<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;"  >
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 24%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="设备名称" prop="equipment_name" style="width: 24%">
              <a-input v-model="queryParams.equipment_name"></a-input>
            </a-form-model-item>
            <a-form-model-item label="计划年份" prop="year" style="width: 24%">
              <a-select v-model="queryParams.year">
                <a-select-option v-for="(item, index) in yearOptions" :key="index" :value="item">{{item+'年'}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 21%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="维保单位" prop="maintenance_unit" style="width: 24%">
                <a-select v-model="queryParams.maintenance_unit" show-search :filter-option="filterOption">
                  <a-select-option v-for="(item, index) in maintenanceUnitList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="设备类型" prop="equipment_type" style="width: 24%">
                <a-select v-model="queryParams.equipment_type">
                  <a-select-option v-for="(item, index) in deviceCodeList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="设备编号" prop="equipment_num" style="width: 24%">
                <a-input v-model="queryParams.equipment_num"></a-input>
              </a-form-model-item>
              <a-form-model-item label="安装位置" prop="address" style="width: 24%">
                <a-select :disabled="disabled" v-model="queryParams.address" show-search :filter-option="filterOption">
                  <a-select-option v-for="(item, index) in addressList" :key="index" :value="item.address">{{item.address}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="防火分区" prop="fire_compartment" style="width: 24%">
                <a-select :disabled="disabled" v-model="queryParams.fire_compartment" show-search :filter-option="filterOption">
                  <a-select-option v-for="(item, index) in fireCompartmentList" :key="index" :value="item.fire_compartment">{{item.fire_compartment}}</a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">计划执行情况</h3>
          <div class="table-btns">
<!--            <a-button @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>-->
<!--            <a-button @click="addRecord" style="margin-right: 10px" type="primary"><a-icon type="plus"></a-icon>新增</a-button>-->
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="maintenance_schedule_id" :loading="tableLoading" :scroll="{ x: 2400}" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="create_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="equipment_type" slot-scope="value,record">{{value.length==2?value.substring(0,1)==0?deviceCodeList[value.replace('0','')-1].dicvalue:deviceCodeList[value-1].dicvalue:''}}</span>
          <span slot="plan_status" slot-scope="value">
            <span :title="value==1?'运行':'停止'">{{value==1?'运行':'停止'}}</span>
          </span>
          <span slot="month_one" slot-scope="value,record">
            <span :style="value?!value.includes('Y')&&!value.includes('J')&&!value.includes('N')&&value!=='0,0'&&value!=='0,0,0'&&value!=='0,0,0,0'&&value!==0?{'color':'red'}:{'color':''}:{'color':''}" @click="openTaskModal(record,moment().year()+'-01')">{{dataHandle(value)}}</span>
          </span>
          <span slot="month_two" slot-scope="value,record">
            <span :style="value?!value.includes('Y')&&!value.includes('J')&&!value.includes('N')&&value!=='0,0'&&value!=='0,0,0'&&value!=='0,0,0,0'&&value!==0?{'color':'red'}:{'color':''}:{'color':''}" @click="openTaskModal(record,moment().year()+'-02')">{{ dataHandle(value)}}</span>
          </span>
          <span slot="month_three" slot-scope="value,record">
            <span :style="value?!value.includes('Y')&&!value.includes('J')&&!value.includes('N')&&value!=='0,0'&&value!=='0,0,0'&&value!=='0,0,0,0'&&value!==0?{'color':'red'}:{'color':''}:{'color':''}" @click="openTaskModal(record,moment().year()+'-03')">{{ dataHandle(value)}}</span>
          </span>
          <span slot="month_four" slot-scope="value,record">
            <span :style="value?!value.includes('Y')&&!value.includes('J')&&!value.includes('N')&&value!=='0,0'&&value!=='0,0,0'&&value!=='0,0,0,0'&&value!==0?{'color':'red'}:{'color':''}:{'color':''}" @click="openTaskModal(record,moment().year()+'-04')">{{ dataHandle(value)}}</span>
          </span>
          <span slot="month_five" slot-scope="value,record">
            <span :style="value?!value.includes('Y')&&!value.includes('J')&&!value.includes('N')&&value!=='0,0'&&value!=='0,0,0'&&value!=='0,0,0,0'&&value!==0?{'color':'red'}:{'color':''}:{'color':''}" @click="openTaskModal(record,moment().year()+'-05')">{{ dataHandle(value)}}</span>
          </span>
          <span slot="month_six" slot-scope="value,record">
            <span :style="value?!value.includes('Y')&&!value.includes('J')&&!value.includes('N')&&value!=='0,0'&&value!=='0,0,0'&&value!=='0,0,0,0'&&value!==0?{'color':'red'}:{'color':''}:{'color':''}" @click="openTaskModal(record,moment().year()+'-06')">{{ dataHandle(value)}}</span>
          </span>
          <span slot="month_seven" slot-scope="value,record">
            <span :style="value?!value.includes('Y')&&!value.includes('J')&&!value.includes('N')&&value!=='0,0'&&value!=='0,0,0'&&value!=='0,0,0,0'&&value!==0?{'color':'red'}:{'color':''}:{'color':''}" @click="openTaskModal(record,moment().year()+'-07')">{{ dataHandle(value)}}</span>
          </span>
          <span slot="month_eight" slot-scope="value,record">
            <span :style="value?!value.includes('Y')&&!value.includes('J')&&!value.includes('N')&&value!=='0,0'&&value!=='0,0,0'&&value!=='0,0,0,0'&&value!==0?{'color':'red'}:{'color':''}:{'color':''}" @click="openTaskModal(record,moment().year()+'-08')">{{ dataHandle(value)}}</span>
          </span>
          <span slot="month_nine" slot-scope="value,record">
            <span :style="value?!value.includes('Y')&&!value.includes('J')&&!value.includes('N')&&value!=='0,0'&&value!=='0,0,0'&&value!=='0,0,0,0'&&value!==0?{'color':'red'}:{'color':''}:{'color':''}" @click="openTaskModal(record,moment().year()+'-09')">{{ dataHandle(value)}}</span>
          </span>
          <span slot="month_ten" slot-scope="value,record">
            <span :style="value?!value.includes('Y')&&!value.includes('J')&&!value.includes('N')&&value!=='0,0'&&value!=='0,0,0'&&value!=='0,0,0,0'&&value!==0?{'color':'red'}:{'color':''}:{'color':''}" @click="openTaskModal(record,moment().year()+'-10')">{{ dataHandle(value)}}</span>
          </span>
          <span slot="month_eleven" slot-scope="value,record">
            <span :style="value?!value.includes('Y')&&!value.includes('J')&&!value.includes('N')&&value!=='0,0'&&value!=='0,0,0'&&value!=='0,0,0,0'&&value!==0?{'color':'red'}:{'color':''}:{'color':''}" @click="openTaskModal(record,moment().year()+'-11')">{{ dataHandle(value)}}</span>
          </span>
          <span slot="month_twelve" slot-scope="value,record">
            <span :style="value?!value.includes('Y')&&!value.includes('J')&&!value.includes('N')&&value!=='0,0'&&value!=='0,0,0'&&value!=='0,0,0,0'&&value!==0?{'color':'red'}:{'color':''}:{'color':''}" @click="openTaskModal(record,moment().year()+'-12')">{{ dataHandle(value)}}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.maintenance_schedule_id">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.maintenance_schedule_id">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.maintenance_schedule_id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal v-model="taskModalVisible" title="保养任务" :width="1200" :centered="true" @cancel="taskModalVisible=false">
      <template slot="footer">
        <a-button @click="taskModalVisible=false">关闭</a-button>
      </template>
      <div class="box-container-inner">
        <a-table :columns="taskTableColumns" :data-source="taskTableData" :customRow="customRow" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="need_finish_time" slot-scope="value">
                  <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''">
                    <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''}}</span>
                  </a-tooltip>
                </span>
          <span slot="check_end_time" slot-scope="value">
                  <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''">
                    <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''}}</span>
                  </a-tooltip>
                </span>
          <span slot="finish_time" slot-scope="value">
                  <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''">
                    <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''}}</span>
                  </a-tooltip>
                </span>
          <span slot="process_node" slot-scope="value">
                  <a-tag :color="orderStepColor[value]||''">{{orderSteps[value]||''}}</a-tag>
                </span>
          <span slot="equipment_type" slot-scope="value,record">{{value.length==2?value.substring(0,1)==0?deviceCodeList[value.replace('0','')-1].dicvalue:deviceCodeList[value-1].dicvalue:''}}</span>
          <span slot="maintenance_period" slot-scope="value">
                  <span :title="value?maintenancePeriodList[value].dicvalue:''">{{value?maintenancePeriodList[value].dicvalue:''}}</span>
                </span>
          <span slot="plan_status" slot-scope="value">
                  <span :title="value==1?'运行':'停止'">{{value==1?'运行':'停止'}}</span>
                </span>
          <span slot="result_code" slot-scope="value">
             <span>{{resultCode[value]}}</span>
          </span>
          <span slot="maintenance_schedule_type" slot-scope="value,record">{{equipmentMaintenanceItemList[value]?equipmentMaintenanceItemList[value].dicvalue:''}}</span>
          <span slot="operation" slot-scope="value, record">
                  <a-dropdown>
                    <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                      <a-icon type="menu"/>
                    </a>
                    <a-menu slot="overlay" @click="operationClick">
                      <a-menu-item :key="'detail-'+record.maintenance_order_id">详情</a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </span>
        </a-table>
        <a-pagination v-model="currentPage1" :total="pagination1.total" :page-size="pagination1.pageSize" show-size-changer @change="pageChange1" @showSizeChange="onShowSizeChange1" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </a-modal>
    <detail-modal :visible.sync="maintenanceTaskVisible" :show-type="maintenanceTaskShowType" :detailData="maintenanceTaskDetailData" @get-operation-result="getTaskTableData"></detail-modal>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey} from "U/index";
import deptselect from "@/mixins/deptselect";
import {
  deleteMaintenanceSchedule, getMaintenanceOrderListByCondition, getMaintenanceScheduleConditionList,
  getMaintenanceScheduleContrastListByCondition, getMaintenanceUnitListByCondition,
} from "A/maintenance";
import {resultCode} from "@/json/orderSteps";
import DetailModal from "V/facilityAndEquipment/maintenanceManagement/maintenance/task/DetailModal";
import {getUserdepListByCondition} from "A/jcgn";
export default {
  mixins: [deptselect,pagination],
  components: {
    DetailModal,
  },
  data() {
    return {
      disabled:true,
      modalVisible: false,
      taskModalVisible: false,
      moment,
      resultCode,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      maintenanceUnitList:[],
      queryParams:{
        monitorpointnum:'',
        monitorpointname:'',
        equipment_type:'',
        equipment_num:'',
        equipment_name:'',
        task_name:'',
        contract_num:'',
        maintenance_unit_id:'',
        plan_status:1,
        fire_compartment:'',
        maintenance_unit:'',
        address:'',
        year:'',
        userdepsid:'',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '设备类型',
          dataIndex: 'equipment_type',
          key: 'equipment_type',
          ellipsis: true,
          scopedSlots: { customRender: 'equipment_type' },
        },
        {
          title: '设备编号',
          dataIndex: 'equipment_num',
          key: 'equipment_num',
          ellipsis: true,
          scopedSlots: { customRender: 'equipment_num' },
        },
        {
          title: '设备名称',
          dataIndex: 'equipment_name',
          key: 'equipment_name',
          ellipsis: true,
        },
        {
          title: '安装位置',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
        {
          title: '防火分区',
          dataIndex: 'fire_compartment',
          key: 'fire_compartment',
          ellipsis: true,
        },
        {
          title: '维保形式',
          dataIndex: 'maintenance_form',
          key: 'maintenance_form',
          ellipsis: true,
        },
        {
          title: '维保单位',
          dataIndex: 'maintenance_unit_name',
          key: 'maintenance_unit_name',
          ellipsis: true,
        },
        {
          title: '维保组',
          dataIndex: 'maintenance_group_name',
          key: 'maintenance_group_name',
          ellipsis: true,
        },
        {
          title: '一月',
          dataIndex: 'month_one',
          key: 'month_one',
          ellipsis: true,
          scopedSlots: { customRender: 'month_one' },
        },
        {
          title: '二月',
          dataIndex: 'month_two',
          key: 'month_two',
          ellipsis: true,
          scopedSlots: { customRender: 'month_two' },
        },
        {
          title: '三月',
          dataIndex: 'month_three',
          key: 'month_three',
          ellipsis: true,
          scopedSlots: { customRender: 'month_three' },
        },
        {
          title: '四月',
          dataIndex: 'month_four',
          key: 'month_four',
          ellipsis: true,
          scopedSlots: { customRender: 'month_four' },
        },
        {
          title: '五月',
          dataIndex: 'month_five',
          key: 'month_five',
          ellipsis: true,
          scopedSlots: { customRender: 'month_five' },
        },
        {
          title: '六月',
          dataIndex: 'month_six',
          key: 'month_six',
          ellipsis: true,
          scopedSlots: { customRender: 'month_six' },
        },
        {
          title: '七月',
          dataIndex: 'month_seven',
          key: 'month_seven',
          ellipsis: true,
          scopedSlots: { customRender: 'month_seven' },
        },
        {
          title: '八月',
          dataIndex: 'month_eight',
          key: 'month_eight',
          ellipsis: true,
          scopedSlots: { customRender: 'month_eight' },
        },
        {
          title: '九月',
          dataIndex: 'month_nine',
          key: 'month_nine',
          ellipsis: true,
          scopedSlots: { customRender: 'month_nine' },
        },
        {
          title: '十月',
          dataIndex: 'month_ten',
          key: 'month_ten',
          ellipsis: true,
          scopedSlots: { customRender: 'month_ten' },
        },
        {
          title: '十一月',
          dataIndex: 'month_eleven',
          key: 'month_eleven',
          ellipsis: true,
          scopedSlots: { customRender: 'month_eleven' },
        },
        {
          title: '十二月',
          dataIndex: 'month_twelve',
          key: 'month_twelve',
          ellipsis: true,
          scopedSlots: { customRender: 'month_twelve' },
        },
        {
          title: '计划年份',
          dataIndex: 'year',
          key: 'year',
          ellipsis: true,
          scopedSlots: { customRender: 'year' },
        },
        // {
        //   title: '操作',
        //   dataIndex: 'operation',
        //   key: 'operation',
        //   ellipsis: true,
        //   align:'center',
        //   scopedSlots: { customRender: 'operation' },
        //   width: 70
        // },
      ],
      taskTableData: [],
      taskTableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        // {
        //   title: '保养单号',
        //   dataIndex: 'order_num',
        //   key: 'order_num',
        //   ellipsis: true,
        // },
        // {
        //   title: '安装位置',
        //   dataIndex: 'address',
        //   key: 'address',
        //   ellipsis: true,
        // },
        // {
        //   title: '防火分区',
        //   dataIndex: 'fire_compartment',
        //   key: 'fire_compartment',
        //   ellipsis: true,
        // },
        // {
        //   title: '设备类型',
        //   dataIndex: 'equipment_type',
        //   key: 'equipment_type',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'equipment_type' },
        // },
        // {
        //   title: '设备编号',
        //   dataIndex: 'equipment_num',
        //   key: 'equipment_num',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'equipment_num' },
        // },
        {
          title: '设备名称',
          dataIndex: 'equipment_name',
          key: 'equipment_name',
          ellipsis: true,
        },
        {
          title: '维/自保单位',
          dataIndex: 'maintenance_unit_name',
          key: 'maintenance_unit_name',
          ellipsis: true,
        },
        {
          title: '维/自保组',
          dataIndex: 'maintenance_group_name',
          key: 'maintenance_group_name',
          ellipsis: true,
        },
        {
          title: '保养周期',
          dataIndex: 'maintenance_period',
          key: 'maintenance_period',
          ellipsis: true,
          scopedSlots: { customRender: 'maintenance_period' },
        },
        // {
        //   title: '保养类型',
        //   dataIndex: 'maintenance_schedule_type',
        //   key: 'maintenance_schedule_type',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'maintenance_schedule_type' },
        // },
        {
          title: '计划完成日期',
          dataIndex: 'need_finish_time',
          key: 'need_finish_time',
          ellipsis: true,
          scopedSlots: { customRender: 'need_finish_time' },
        },
        {
          title: '保养完成日期',
          dataIndex: 'check_end_time',
          key: 'check_end_time',
          ellipsis: true,
          scopedSlots: { customRender: 'check_end_time' },
        },
        {
          title: '保养结论',
          dataIndex: 'result_code',
          key: 'result_code',
          ellipsis: true,
          scopedSlots: { customRender: 'result_code' },
        },
        // {
        //   title: '计划完成日期',
        //   dataIndex: 'need_finish_time',
        //   key: 'need_finish_time',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'need_finish_time' },
        // },
        // {
        //   title: '节点状态',
        //   dataIndex: 'process_node',
        //   key: 'process_node',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'process_node' },
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      deviceCodeList:[],
      yearOptions: [],
      addressList:[],
      fireCompartmentList:[],
      equipmentMaintenanceItemList:[],
      userdepidCascaderSelected: [],
      maintenanceTaskVisible: false,
      maintenanceTaskShowType: '',
      maintenanceTaskDetailData: {},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "计划执行看板"
    },
    breadcrumb() {
      const pages = [{name:"设施设备管理",path:""}]
      pages.push({name:"保养管理",path:""})
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
        getUserdepListByCondition({userdepid:this.queryParams.userdepsid}).then(res=>{
          if(res&&res.returncode==='0'){
            if(res.item[0].levelid==='15'){
              this.getQueryCondition()
              this.disabled=false
            }
          }
        })
      }else {
        this.queryParams.userdepsid = '';
        this.disabled=true
      }
    },
  },
  created() {
    this.getYearOptions()
    this.getTableData()
    this.getQueryCondition();
    this.initDeptOptionsAll();
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.deviceCodeList = dictionary.deviceCodeList
      this.equipmentParamsList = dictionary.equipmentParamsList
      this.equipmentCategoryList = dictionary.equipmentCategoryList
      this.equipmentChildCategoryList = dictionary.equipmentChildCategoryList
      this.equipmentStatusList = dictionary.equipmentStatusList
      this.maintenancePeriodList = dictionary.maintenancePeriodList
      this.equipmentMaintenanceItemList = dictionary.equipmentMaintenanceItemList
    }
  },
  methods:{
    getQueryCondition(){
      getMaintenanceScheduleConditionList({userdepsid:this.queryParams.userdepsid}).then(res=>{
        if(res && res.returncode == '0') {
          this.addressList=res.addressList
          this.fireCompartmentList=res.fireCompartmentList
        }
      })
      getMaintenanceUnitListByCondition({userdepsid:this.userInfo.userdepid}).then(res=> {
        if (res && res.returncode == '0') {
          this.maintenanceUnitList = res.item
        }
      })
    },
    openTaskModal(record,order_date){
      this.getTaskTableData(true,record.equipment_name,record.equipment_num,order_date,record.monitorpointnum)
      this.taskModalVisible=true
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getMaintenanceScheduleContrastListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.disabled=true
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
      let dictionary = getCache('dictionary', true);
      if(dictionary) {
        this.deviceCodeList = dictionary.deviceCodeList
        this.equipmentParamsList = dictionary.equipmentParamsList
        this.equipmentCategoryList = dictionary.equipmentCategoryList
        this.equipmentChildCategoryList = dictionary.equipmentChildCategoryList
        this.equipmentStatusList = dictionary.equipmentStatusList
        this.maintenancePeriodList = dictionary.maintenancePeriodList
        this.equipmentMaintenanceItemList = dictionary.equipmentMaintenanceItemList
      }
      this.getYearOptions()
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let maintenance_schedule_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'maintenance_schedule_id', maintenance_schedule_id);
      if(type == 'delete') {
        this.deleteConfirm(maintenance_schedule_id, record)
      }else{
        this.showModal(type,record)
      }
    },
    showModal(type,record){
      if(type=='detail'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }else if(type=='edit'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }
    },
    addRecord(){
      this.modalVisible=true
      this.modalShowType='add'
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.maintenance_schedule_id);
      }).catch(()=>{

      }) ;
    },
    delete(maintenance_schedule_id) {
      this.showLoading();
      if(maintenance_schedule_id) {
        let params = {
          maintenance_schedule_id
        };
        deleteMaintenanceSchedule(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm('确定要删除选中数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(this.batchrowids);
      }).catch(()=>{

      }) ;
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      this.batchrowids = selectedRowKeys.join('|');
    },
    dataHandle(value){
      let result;
      if(value){
        if(value.includes(',')&&!value.includes('Y')&&!value.includes('J')&&!value.includes('N')){
          let value1;
          let value2;
          let value3;
          let value4;
          if(value.split(",").length===1){
            value1 = value
            result = (value1>0?value1==="999"?"X":"+"+value1:value1)
          }else if(value.split(",").length===2){
            value1 = value.split(",")[0]
            value2 = value.split(",")[1]
            result = (value1>0?value1==="999"?"X":"+"+value1:value1)+"，"+(value2>0?value2==="999"?"X":"+"+value2:value2)
          }else if(value.split(",").length===3){
            value1 = value.split(",")[0]
            value2 = value.split(",")[1]
            value3 = value.split(",")[2]
            result = (value1>0?value1==="999"?"X":"+"+value1:value1)+"，"+(value2>0?value2==="999"?"X":"+"+value2:value2)+"，"+(value3>0?value3==="999"?"X":"+"+value3:value3)
          }else if(value.split(",").length===4){
            value1 = value.split(",")[0]
            value2 = value.split(",")[1]
            value3 = value.split(",")[2]
            value4 = value.split(",")[3]
            result = (value1>0?value1==="999"?"X":"+"+value1:value1)+"，"+(value2>0?value2==="999"?"X":"+"+value2:value2)+"，"+(value3>0?value3==="999"?"X":"+"+value3:value3)+"，"+(value4>0?value4==="999"?"X":"+"+value4:value4)
          }
        }else{
          result = value==="999"?"X":value?(!value.includes('Y')&&!value.includes('J')&&!value.includes('N')&&value>0?'+'+value:value):""
        }
      }else{
        result=value
      }
      return result;
    },
    getTaskTableData(firstPage,equipment_name,equipment_num,order_date,monitorpointnum){
      if(firstPage) {
        this.currentPage1 = 1;
      }
      this.tableLoading = true;
      let params = {
        order_date:order_date,
        equipment_num:equipment_num,
        equipmentname:equipment_name,
        monitorpointnum:monitorpointnum,
        pageno: this.currentPage1,
        pagesize: this.pagination1.pageSize
      };
      getMaintenanceOrderListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.taskTableData = res.item;
          this.pagination1.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.maintenanceTaskDetailData=record
            this.maintenanceTaskShowType='detail'
            this.maintenanceTaskVisible=true
          },
        },
      }
    },
    getYearOptions() {
      this.yearOptions = [];
      let currentYear = moment().year();
      let lastYear = moment().year()-1;
      for(let i=1; i>-1; i--) {
        this.yearOptions.push(currentYear+i+'');
      }
      this.yearOptions.push(lastYear+'')
      this.queryParams.year=currentYear+''
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
}
</script>
<style scoped>

</style>